import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail4.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page4 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav><img className="w-full" src={Detail1} alt="" />
      <h1 className="my-6 text-center text-4xl">Dubai's Property Investment Dilemma: Choosing Between Townhouses and Villas in 2024</h1>
        <div className=" mx-auto flex items-center flex-col">
        <div className="w-10/12">
          <p className="mt-5 ">
            The population of Dubai and the growth of the tourism sector make
            clients actively look for premium housing, including townhouses and
            villas. It becomes the investor’s responsibility to consider a
            number of factors in order to arrive at a correct decision between
            these two types of property.
          </p>

          <h2 className="my-5 font-bold">
            Exploring Property Types: Villa vs. Townhouse
          </h2>
          <p className="my-5 ">
            Apartments are no doubt great, but villas stand for the real luxury
            of living in Dubai and the ease of accessing some of the best
            neighborhoods and service providers. The return on investment
            through a villa also includes a relatively expensive first payment;
            however, the potential for good rental returns, coupled with the
            possibility of long-term appreciation, makes it an interesting
            option.
          </p>
          <p className="my-5 ">
            In contrast, townhouses are more communal and priced at a slightly
            lower level than villas, but they have more living space. Housing
            projects such as new townhouse units within the Meydan development
            offer investors a cheaper entry value when it comes to property
            investment in Dubai. Although not as big as villas, townhouses have
            adopted modern architecture and contain highly desired tech
            features; as such, they are a functional investment opportunity for
            any client targeting promising yields within Dubai’s growing real
            estate market.
          </p>

          <h2 className="my-5 font-bold">
            Key Differences between a Villa and a Townhouse
          </h2>
          <p className="my-5 ">
            Villas focus on seclusion and privacy and therefore contain
            generous-sized lawns, ornate gardens, and resort-like facilities. On
            the other hand, townhouses stimulate togetherness by having
            interconnected structures and spaces and/or by having dedicated
            facilities for this use. While villas cater to multi-generational
            households seeking luxury and seclusion, townhouses appeal to young
            professionals and families desiring convenience and modernity within
            close-knit neighborhoods.
          </p>

          <h2 className="my-5 font-bold">
            Space Allocation and Privacy Considerations
          </h2>
          <p className="my-5 ">
            Dubai's residential villas prioritize spaciousness, often spanning
            over 3,000+ sq. ft., to accommodate privacy and exclusivity.
            Townhouses, with cleverly planned layouts across 2,000 sq. ft. or
            less, cater to tenant demographics seeking communal living in
            well-designed neighborhoods. While villas offer customization
            opportunities, townhouses foster community bonds through shared
            amenities and proximity to neighbors.
          </p>

          <h2 className="my-5 font-bold">
            Evaluating Rental Yields and Resale Values
          </h2>
          <p className="my-5 ">
            Both villas and townhouses in Dubai offer attractive investor
            returns, with average gross rental yields ranging from 5-9%.
            Selected communities demonstrate exceptional yields, indicating
            strong demand for both property types.
          </p>

          <h2 className="my-5 font-bold">
            Making an Informed Investment Choice: Villa or Townhouse
          </h2>
          <p className="my-5 ">
            Villas offer luxury living but require higher capital, while
            townhouses provide accessible entry points into ownership with
            community infrastructure. By evaluating tenant preferences and
            market trends, investors can make informed decisions aligned with
            their investment goals in Dubai's thriving property market in 2024.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page4;
