import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail12.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page12 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav><img className="w-full" src={Detail1} alt="" />
      <h1 className="my-6 text-center text-4xl">The Future of Commercial Real Estate in Dubai: Opportunities and Challenges</h1>
        <div className=" mx-auto flex items-center flex-col">
        <div className="w-10/12">
          <p className="mt-5 ">
            Dubai's commercial real estate market is poised for growth, driven
            by a dynamic economy and strategic location. The city serves as a
            major business hub, attracting multinational corporations and
            startups alike. This has led to a steady demand for office spaces,
            retail outlets, and industrial facilities. However, the market also
            faces challenges, such as fluctuating oil prices and global economic
            uncertainties, which can impact investor confidence and demand.
          </p>

          <p className="my-10 ">
            One of the key opportunities in Dubai's commercial real estate
            market is the rise of flexible workspaces. The demand for coworking
            spaces and serviced offices has surged, driven by the changing work
            culture and the growth of the gig economy. Companies are
            increasingly seeking flexible office solutions that offer cost
            savings and adaptability. Developers and investors are capitalizing
            on this trend by creating modern, tech-enabled workspaces that cater
            to the needs of businesses of all sizes.
          </p>

          <p className="my-10 ">
            The retail sector in Dubai is also evolving, with a shift towards
            experiential retail. Traditional brick-and-mortar stores are
            transforming into interactive and engaging spaces that offer unique
            customer experiences. This trend is driven by the need to compete
            with the growing e-commerce market. Retail developers are
            incorporating entertainment, dining, and leisure activities into
            their projects to attract foot traffic and enhance the shopping
            experience.
          </p>

          <p className="my-10 ">
            Industrial and logistics properties are another area of growth in
            Dubai's commercial real estate market. The rise of e-commerce has
            increased the demand for warehouses and distribution centers.
            Dubai's strategic location, advanced infrastructure, and free zones
            make it an ideal logistics hub for regional and international trade.
            Investors are recognizing the potential of this sector and are
            investing in state-of-the-art facilities to meet the growing demand.
          </p>

          <p className="my-10 ">
            Despite the opportunities, the commercial real estate market in
            Dubai faces challenges. Economic fluctuations, regulatory changes,
            and geopolitical tensions can impact investor sentiment and market
            stability. Additionally, the oversupply of commercial spaces in
            certain areas can lead to increased vacancy rates and pressure on
            rental yields. Developers and investors need to adopt strategic
            planning and market analysis to navigate these challenges
            effectively.
          </p>

          <p className="my-10 ">
            Sustainability is also becoming a critical factor in the commercial
            real estate market. Businesses are increasingly seeking eco-friendly
            office spaces that align with their corporate social responsibility
            goals. Developers are responding by incorporating green building
            practices and obtaining sustainability certifications for their
            projects. This not only enhances the appeal of commercial properties
            but also contributes to the long-term resilience of the market.
          </p>

          <p className="my-10 ">
            Looking forward, the future of commercial real estate in Dubai will
            be shaped by technological advancements, evolving work and retail
            trends, and sustainable development practices. By leveraging these
            opportunities and addressing the challenges, Dubai can continue to
            thrive as a leading commercial real estate market, attracting global
            investors and businesses.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page12;
