import React, { useState } from "react";
import Banner from "../Images/Footer.png";
import Logo from "../Images/wlogo.png";
import Linkedin from "../Images/Linkedin.png";
import fb from "../Images/Facebook.png";
import google from "../Images/google.png";
import mail from "../Images/mail.png";
import phone from "../Images/phone.png";
import location from "../Images/location.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div className="w-full h-auto bg-black text-white">
        <div className="w-11/12 mx-auto py-5">
          <div className="flex flex-col md:flex-row justify-between">
            {/* Logo and Social Icons */}
            <div className="mb-6 md:mb-0 flex flex-col items-center md:items-start">
              <img className="w-28" src={Logo} alt="Logo" />
              <div className="flex mt-4 space-x-4">
                <a href="https://www.facebook.com/profile.php?id=61567129477463">
                  <img src={fb} alt="Facebook" className="" />
                </a>
                <a href="https://www.instagram.com/pointx_realty/">
                  <img src={google} alt="Google" className="" />
                </a>
                <a href="https://www.linkedin.com/company/105403509/admin/dashboard/">
                  <img src={Linkedin} alt="LinkedIn" className="" />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div className="mb-6 md:mb-0 text-center md:text-left">
              <ul>
                <li className="font-semibold text-xl mb-2">Quick Links</li>
                <li>
                  <Link to={"/developers#top"}>Search for Property</Link>
                </li>
                <li>
                  <Link to={"/about#top"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/communities#top"}>Community</Link>
                </li>
                <li>
                  <Link to={"/contact#top"}>Contact</Link>
                </li>
              </ul>
            </div>

            {/* Services */}
            <div className="mb-6 md:mb-0 text-center md:text-left">
              <ul>
                <li className="font-semibold text-xl mb-2">Services</li>
                <li>
                  <Link to="/services#Effortless">Effortless Property Management</Link>
                </li>
                <li>
                  <Link to="/services#SearchSelection">
                    Property Search and Selection
                  </Link>
                </li>
                <li>
                  <Link to="/services#Investment">Investment Consultancy</Link>
                </li>
                <li>
                  <Link to="/services#HomeInspection">Home Inspection/Snagging</Link>
                </li>
              </ul>
            </div>

            {/* Contact Information */}
            <div className="mb-6 md:mb-0 text-center md:text-left">
              <ul>
                <li className="font-semibold text-xl mb-2">Contact</li>
                <li className="flex items-center justify-center md:justify-start mt-3">
                  <img className="mr-2 w-6 h-6" src={location} alt="Location" />
                  <span className="text-sm">
                    1908 DAMAC Smart Heights - Barsha Heights
                  </span>
                </li>
                <li className="flex items-center justify-center md:justify-start mt-3">
                  <img className="mr-2 w-5 h-5" src={phone} alt="Phone" />
                  <span className="text-sm">+971588098415</span>
                </li>
                <li className="flex items-center justify-center md:justify-start mt-3">
                  <img className="mr-2 w-5 h-5" src={mail} alt="Email" />
                  <span className="text-sm">marketing@pointxrealty.com</span>
                </li>
              </ul>
            </div>
          </div>

          <hr className="h-[2px] my-4 bg-white" />

          {/* Footer Bottom */}
          <div className="flex flex-col md:flex-row justify-between text-center md:text-left">
            <div className="mb-4 md:mb-0">© 2024 All Rights Reserved.</div>
            <div className="flex justify-center md:justify-end space-x-4">
              <Link to="#" className="border-r-2 border-white pr-4">
                Terms Of Use
              </Link>
              <Link to="#">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
