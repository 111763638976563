import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../Images/Logo.png";
import Hero1 from "./Bayz102Images/1.png";
import Hero2 from "./Bayz102Images/2.png";
import Amenities from "./Bayz102Images/4.png";
import Gallery1a from "./Bayz102Images/Gallery1a.jpg";
import Gallery2a from "./Bayz102Images/Gallery2a.jpg";
import Gallery3a from "./Bayz102Images/Gallery3a.jpg";
import Gallery4a from "./Bayz102Images/Gallery4a.jpg";
import Gallery5a from "./Bayz102Images/Gallery5a.jpg";
import BulletImage from "./Bayz102Images/Bullet.png";
import Location from "./Bayz102Images/location.png";
import DA from "./Bayz102Images/DA.png";
import pdf from "./Bayz102Images/pdf.png";
import touch from "./Bayz102Images/touch.png";
import info from "./Bayz102Images/info.png";
import Footer from "../../WhyDubaiComps/Footer";
// import Brochure from "./Bayz102.pdf";

import useScrollToTop from "../../Scroll";

const Bayz102 = () => {
  useScrollToTop();

  const alternateImages = [
    Gallery1a,
    Gallery2a,
    Gallery3a,
    Gallery4a,
    Gallery5a,
  ];
  const NextArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &gt;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white text-black cursor-pointer rounded-full z-10"
      onClick={onClick}
    >
      &lt;
    </div>
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const handleDownload = () => {
    const newErrors = {
      name: !formValues.name,
      email: !formValues.email,
      phone: !formValues.phone,
    };

    setErrors(newErrors);

    // If there are no errors, proceed with download
    if (!newErrors.name && !newErrors.email && !newErrors.phone) {
      const link = document.createElement("a");
      // link.href = Brochure; // Use the imported PDF file here
      link.download = "Bayz102Brochure.pdf"; // You can set the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("Download initiated");
      alert("Thanks for contacting us. Our team will be in touch with you soon.");
      setFormValues({
        name: "",
        email: "",
        phone: "",
      });
      setShowPopup(false);
    }
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openModal = () => {
    setIsGalleryOpen(true);
  };

  const closeModal = () => {
    setIsGalleryOpen(false);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <div className="relative w-full ">
        {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>    <div>
          {/* Section that triggers the popup */}
          <section className="w-full h-full cursor-pointer" onClick={openModal}>
            <img src={Hero1} alt="" className="w-full h-full object-cover" />
          </section>

          {/* Popup Modal */}
          {isGalleryOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
              <div className="relative w-11/12 max-w-6xl bg-white p-6">
                {/* Close Button */}
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  &#x2715; {/* Cross icon */}
                </button>

                {/* Slider */}
                <Slider {...sliderSettings}>
                  {alternateImages.map((image, index) => (
                    <div key={index} className="w-full h-auto">
                      <img
                        src={image}
                        alt={`Alternate Iage ${index + 1}`}
                        className="w-full h-auto"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-5 flex justify-end w-10/12 mx-auto relative">
        <Link
          to={"/Danube#top"}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl mr-10 text-white"
        >
          GO Back
        </Link>
        <button
          onClick={togglePopup}
          className="bg-[#D8B56D] uppercase  px-5 py-2 rounded-xl text-white"
        >
          Pricing Plan
        </button>
        {isOpen && (
          <div className="absolute top-full z-10 right-0 mt-2 bg-white shadow-lg rounded-lg p-4 w-96">
            <h3 className="text-xl font-bold mb-2">Payment Plans</h3>
            <ul className="list-disc pl-5">
              <li className="bg-transparent text-black text-lg">
                Options:  1% payment plan
              </li>
              <li className="bg-transparent text-black text-lg">
                Starting from AED 1.175 M
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="bg-[#F5F5F5] pt-10">
        <h1 className="text-5xl font-semibold text-center">
          Bayz102 By Danube{" "}
        </h1>
        <p className="w-10/12 mx-auto text-2xl text-left my-10">
        Danube Bayz 102, launched and developed by Danube Properties, is one of the finest residential spaces located in Dubai District. Boasting comfortable, modern interiors, large functional space, and high quality work over the floor, the development offers solutions for the most demanding clients. The location of Danube Bayz 102 amidst Dubai’s modern skyline brings all the urban functionality required by business persons, families, and investors for a comfortable contemporary lifestyle whilst offering the quintessential flavour of an aristocratic city in the Arabian Peninsula.
        
        </p>
        <div className="flex justify-center items-center ">
          <img src={Hero2} alt="" />
        </div>
        <div className="bg-custom-gradient  ">
          <h1 className="text-center text-4xl font-semibold py-10">
            Amenities
          </h1>
          <img className="mx-auto pb-10   " src={Amenities} alt="" />
        </div>
      </div>

      <h2 className="text-4xl text-center my-10 font-semibold">
        Location Advantages
      </h2>
      <div className="flex justify-center mt-16 p-4 space-x-8">
        {/* Connectivity Section */}
        <div className="w-1/3 p-4 bg-white rounded">
          <h2 className="text-2xl font-bold mb-4">Connectivity</h2>
          <div className="space-y-6">
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">2 mins Burj Khalifa. </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">5 Mins City Walk. </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
                1 Mins Business Bay Metro.{" "}
              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
                5 Mins Museum of Future.{" "}
              </p>
            </div>

            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">3 Mins Dubai Mall. </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">8 Mins Dubai Frame.</p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="w-1/5 p-4 flex justify-center bg-white rounded">
          <img src={Location} alt="Placeholder" className="rounded" />
        </div>

        {/* Neighborhood Section */}
        <div className="w-1/3 p-4 bg-white rounded">
          <h2 className="text-2xl font-bold mb-4">Neighborhood</h2>
          <div className="space-y-6">
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
              Convenient access to Dubai Metro stations for seamless travel.
              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
              Close to major highways like Sheikh Zayed Road and Al Khail Road.
              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
              A short drive from Dubai International Airport.              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
                Nearby family-friendly attractions like theme parks and
                waterparks.
              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
                Close to prestigious golf courses and sports facilities.
              </p>
            </div>
            <div className="flex items-start">
              <img
                src={BulletImage}
                alt="Bullet Point"
                className="w-4 h-4 mr-3 mt-2"
              />
              <p className="text-black text-xl my-1">
                Access to premium fitness centers and wellness spas.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-[#f5f5f5] py-10 ">
        <h2 className="text-4xl mb-10 font-bold  text-center">
          Design and Architecture
        </h2>

        <div className=" bg-[#f5f5f5] flex justify-center w-11/12 mx-auto ">
          <div className=" p-4 w-1/2  mr-10">
            <p className="text-xl text-black">
            Danube Bayz 102’s design for architectural features follows modern architectural design features based on straight lines that make the building look smart and trendy. A special attention is paid to design, spaciousness and high quality of finishes, colorful glazing, and fresh air. Every unit is designed and built to include sufficient space and both elegance and comfort. Aesthetics such as tall heights, high-standard quality materiality, and lighting convey a feeling of affluence necessary in the luxurious lifestyle of those residing in such houses, besides producing a comfortable image of fixtures and fittings that in turn create an elegant feeling in the surrounding environment. It is these overall tendencies that reveal perhaps the greatest concern with regard to the design of the buildings and their spaces, mindful of Danube’s desire to deliver the aesthetic along with the useful and practical.
            </p>
          </div>

          <div className=" bg-[#f5f5f5]">
            <img src={DA} alt="Placeholder" className="rounded  " />
          </div>
        </div>

        <div>
          <button
            className="px-6 py-3 flex bg-[#D8B56D] uppercase  text-white rounded-3xl mx-auto mt-20 w-96 justify-center"
            onClick={() => setShowPopup(true)}
          >
            Download Brochure <img className="ml-3" src={pdf} alt="PDF icon" />
          </button>

         {showPopup && (
  <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-6 sm:p-8 md:p-10 w-11/12 sm:w-3/4 md:w-1/3 h-auto sm:h-auto md:h-[90vh] relative">
      {/* Close button */}
      <button
        className="absolute top-4 right-4 text-gray-600 sm:top-2 sm:right-2"
        onClick={() => setShowPopup(false)}
      >
        &times;
      </button>
      <div className="py-8 sm:py-10 flex flex-col justify-between h-full">
        <h2 className="text-3xl sm:text-4xl font-bold mb-4">DOWNLOAD</h2>
        <h3 className="text-3xl sm:text-4xl text-[#000] font-bold mb-6">
          THE BROCHURE
        </h3>
        <p className="mb-6 text-lg">Enter your details to get your copy.</p>

        {/* Form fields */}
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formValues.name}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.name ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.name && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formValues.email}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.email ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.email && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formValues.phone}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.phone ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.phone && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        {/* Download button */}
        <button
          className="w-full mt-6 bg-[#D8B56D] uppercase text-white p-3 rounded"
          onClick={handleDownload}
        >
          Download
        </button>

        <p className="mt-6 text-lg text-gray-600">
          By submitting this form you agree to subscribe to updates from PointX
          Realty and be sent information in relation to its products and services.
        </p>
      </div>
    </div>
  </div>
)}

        </div>
      </div>
      <div className="flex w-full  bg-[#000] py-10">
        <div className="w-full rounded-l-2xl bg-white p-4 ">
          <div className=" w-10/12 fon mx-auto  p-6  ">
            <img alt="" src={touch} className=" mb-4" />
            <form className="pb-10">
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="NAME"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="EMAIL"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="PHONE NUMBER"
                />
              </div>

              <button
                className="bg-[#D8B56D] w-full text-white font-bold uppercase py-2 px-4 rounded "
                type="button"
              >
                SEND
              </button>
            </form>
            <img src={info} alt="" />
          </div>
        </div>

        <div className="  rounded-r-2xl h-[470px] p-4 flex justify-center items-center">
          <div className=" p-6  rounded shadow-md">
          
            <div className="rounded overflow-hidden shadow-lg">
              <iframe
                className="h-[470px] w-[470px] rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.168505642546!2d55.173861210957384!3d25.096156677683474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b798cbfff8b%3A0x70a7f5e105a580e!2sDAMAC%20Smart%20Heights!5e0!3m2!1sen!2sus!4v1728393772385!5m2!1sen!2sus"
                allowFullScreen=""
                loading="lazy"
                title="map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bayz102;
