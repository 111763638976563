import React, { useState } from "react";
import mail from "../Images/mail2.png";
import Linkedin from "../Images/Linkedin.png";
import fb from "../Images/Facebook.png";
import google from "../Images/google.png";  
import whatsapp from "../Images/whatsapp2.png";
const ContactForm = () => {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const response = await fetch("https://formspree.io/f/xwpkpjea", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      setStatus("SUCCESS");
      form.reset();
    } else {
      setStatus("ERROR");
    }
  };

  return (
    <div className="bg-[#EDEDED] w-full md:w-3/4 mx-auto rounded-xl my-10 py-8 px-4">
    <h1 className="font-bold text-center text-2xl md:text-3xl mb-4">Get In Touch</h1>
  
    <div className="flex flex-col md:flex-row justify-center gap-8 w-full p-6">
      {/* Appointment Form */}
      <form
        onSubmit={handleSubmit}
        className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-6"
      >
        <div className="flex flex-col gap-4 mb-4">
          <div className="w-full">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="w-full bg-white py-3 px-5 shadow-md rounded-lg"
              required
            />
          </div>
          <div className="w-full">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="w-full bg-white py-3 px-5 shadow-md rounded-lg"
              required
            />
          </div>
          <div className="w-full">
            <input
              type="tel"
              name="phone"
              placeholder="Enter Your Phone Number"
              className="w-full bg-white py-3 px-5 shadow-md rounded-lg"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <textarea
            name="details"
            placeholder="Additional Details"
            className="w-full bg-white py-3 px-5 shadow-md rounded-lg h-28"
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-[#D8B56D] mt-5 text-white py-3 px-10 rounded-full w-full md:w-auto"
          >
            Make Appointment
          </button>
        </div>
        {status === "SUCCESS" && (
          <p className="text-center text-green-600 mt-5">
            Message sent successfully!
          </p>
        )}
        {status === "ERROR" && (
          <p className="text-center text-red-600 mt-5">
            Oops! There was an error.
          </p>
        )}
      </form>
  
      {/* Contact Information */}
      <div className="w-full md:w-1/2 p-6">
        <h3 className="text-2xl font-bold mb-2">Schedule an Appointment</h3>
        <p className="mb-6">
          We’re here to assist with any questions or concerns you may have. Feel
          free to reach out Contact us, and our team will respond as soon as
          possible.
        </p>
        <div className="flex flex-col gap-4 mb-4">
          <a
            href="https://wa.me/971588098415"
            rel="noopener noreferrer"
            className="flex items-center bg-[#D8B56D] text-white p-4 rounded-lg"
          >
            <img src={whatsapp} alt="WhatsApp Icon" />
            <span className="ml-3">+971588098415</span>
          </a>
          <a
            href="mailto:marketing@pointxrealty.com"
            className="flex items-center bg-[#D8B56D] text-white p-4 rounded-lg"
          >
            <img src={mail} alt="Mail Icon" />
            <span className="ml-3">marketing@pointxrealty.com</span>
          </a>
        </div>
        <div className="mb-4">
          <p className="font-bold">Working Hours</p>
          <p className="text-gray-600">Open daily: 10:00 AM - 07:00 PM</p>
        </div>
        <div className="mb-4">
          <p className="font-bold">How To Reach Us Out </p>
          <p className="text-gray-600">
            UAE:+971588098415 USA:+13034781109 UK:+447843424959
          </p>
        </div>
        <div className="mb-4">
          <p className="font-bold">Visit Our Office</p>
          <p className="text-gray-600">
            1908 DAMAC Smart Heights - Barsha Heights
          </p>
        </div>
      </div>
    </div>
  </div>
  

  );
};

export default ContactForm;
