import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import Community1 from "../Images/Community1.png";
import Community2 from "../Images/Community2.png";
import Community3 from "../Images/Community3.png";
import Community4 from "../Images/Community4.png";
import Community5 from "../Images/Community5.png";

import Topc1 from "../Images/Topc1.png"; // Mobile image imports
import Topc2 from "../Images/Topc2.png";
import Topc3 from "../Images/Topc3.png";
import Topc4 from "../Images/Topc4.png";
import Topc5 from "../Images/Topc5.png";

const imageDataWeb = [
  { src: Community1, text: "DownTown Dubai" },
  { src: Community2, text: "Dubai Hills Estate" },
  { src: Community3, text: "Dubai Marina" },
  { src: Community4, text: "Palm Jumeirah" },
  { src: Community5, text: "Business Bay" },
];

const imageDataMobile = [
  { src: Topc1, text: "DownTown Dubai" },
  { src: Topc2, text: "Dubai Hills Estate" },
  { src: Topc3, text: "Dubai Marina" },
  { src: Topc4, text: "Palm Jumeirah" },
  { src: Topc5, text: "Business Bay" },
];

const Communities = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      offset: 200,
    });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // To check on initial load

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle screen resizing to check if it's mobile or not
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const imageData = isMobile ? imageDataMobile : imageDataWeb;

  return (
    <section className="p-6">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold">Communities</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-6">
        {imageData.map((image, index) => (
          <div
            key={index}
            className="relative group"
            data-aos="fade-up"
            data-aos-delay={`${index * 200}`}
          >
            <Link to="/communities#top">
              <img
                src={image.src}
                alt={image.text}
                className="w-full object-cover rounded-lg sm:h-[180px] md:h-[200px] lg:h-[220px] xl:h-[450px] aspect-[16/9] sm:aspect-[4/3]"
                style={{ minHeight: "180px" }} // Ensuring the minimum height
              />
              <div className="absolute inset-0 bg-black/20 flex items-center justify-center transition-opacity rounded-lg">
                <span className="text-white  text-xl font-bold">{image.text}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="flex justify-center py-10">
        <Link
          to={"/communities#top"}
          type="button"
          className="text-white bg-[#D8B56D] w-[20%] font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Explore all
        </Link>
      </div>
    </section>
  );
};

export default Communities;
