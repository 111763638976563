import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../Images/Logo.png";
import Slide1 from "../Images/slide1.jpg";
import Slide2 from "../Images/slide2.jpg";
import Slide4 from "../Images/slide4.jpg";
import Slide5 from "../Images/slide5.jpg";
import FilterBar from "./FilterBar";

const CustomSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Adjust the interval time as needed
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full mx-auto">
      {/* Desktop Navbar */}
      <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
            <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
          <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>

      {/* Image and Content Transition */}
      <div className="relative h-[100vh]  overflow-hidden">
  {/* Static FilterBar positioned over the slider */}

  <AnimatePresence mode="wait">
    {slides.map((slide, index) => (
      index === currentIndex && (
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="absolute  left-0 w-full h-full"
        >
          <img
            src={slide.image}
            alt={`Slide ${index + 1}`}
            className="w-full h-full object-cover"
          />
          <div className="absolute ml-8  inset-0 flex flex-col md:flex-row items-center justify-center md:justify-start px-4 md:px-12 pt-12 md:pt-0 text-white">
            <motion.div
              className="bg-white/70 p-6 md:p-8 rounded-lg max-w-full md:max-w-[50%]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <h2 className="text-xl md:text-3xl font-bold mb-4 uppercase text-[#000]">
                {slide.title}
              </h2>
              <p className="mb-2 text-sm md:text-lg text-black">{slide.description1}</p>
              <p className="mb-2 text-sm md:text-lg text-black">{slide.description2}</p>
              <p className="mb-6 text-sm md:text-lg text-black">{slide.description3}</p>
              <Link
                to="/contact"
                className="px-3 py-2 md:px-6 md:py-3 bg-[#D8B56D] text-white uppercase rounded"
              >
                Contact Us
              </Link>
            </motion.div>
          </div>
        </motion.div>
      )
    ))}
  </AnimatePresence>
  <div className=" hidden md:block absolute md:bottom-0 left-0 w-full z-10 p-4">
    <FilterBar />
  </div>
</div>
  <div className="block md:hidden w-full  p-4">
    <FilterBar />
  </div>


    </div>
  );
};

const slides = [
  {
    image: Slide1,
    title: "A space designed just for you",
    description1: "Join a thriving community with PointX Realty. We’re dedicated to connecting",
    description2: " you with neighborhoods that offer both comfort and a ",
    description3: "sense of belonging",
  },
  {
    image: Slide2,
    title: "Step inside your future paradise",
    description1: "Secure your investment with PointX Realty. Our expert insights into Dubai's",
    description2: " real estate market empower you to make informed decisions for lasting value ",
    description3: "",
  },
  {
    image: Slide4,
    title: "Where comfort meets elegance",
    description1: "Find your ideal home with PointX Realty. Our focus on luxury and lifestyle ",
    description2: "ensures you’ll discover a property that reflects your unique vision ",
    description3: "",
  },
  {
    image: Slide5,
    title: "Your Dream Home Awaits",
    description1: "Explore Dubai’s top real estate opportunities with PointX real estate. From ",
    description2: "stunning developments to dynamic communities, see how we’re setting new",
    description3: "standards in real estate.",
  },
];

export default CustomSlider;
