import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/Logo.png";
import useScrollToTop from "../Scroll";
import Properties from "../Offplan Pages/Properties";
import Prop1 from "../Images/Prop1.png";
import Prop2 from "../Images/Prop2.png";
import Prop3 from "../Images/Prop3.png";
import Prop4 from "../Images/Prop4.jpg";
import Prop5 from "../Images/Prop5.jpg";
import Prop6 from "../Images/Prop6.jpg";
import Prop7 from "../Images/Prop7.jpg";
import Area from "../Images/area.png";
import Beds from "../Images/beds.png";
import vector from "../Images/vector.png";
import Baths from "../Images/baths.png";
import Menu from "../Images/menu.png";
import Cars from "../Images/car.png";
import Footer from "../WhyDubaiComps/Footer";
const OffPlanProperties = () => {
  useScrollToTop();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      {/* Desktop Navbar */}
     <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>    <div>
        
      </div>
      {/* <CustomFilter /> */}
      <div className="w-11/12 mx-auto flex flex-col md:flex-row  p-6 gap-12">
        {/* Property Card 1 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/tilalAlGhaf#top"}>
              <img className="w-full h-64 object-cover" src={Prop1} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/tilalAlGhaf#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Tilal Al Ghaf
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom Villa in Tilal Al Ghaf
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">605 /6,515 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">6 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">7 Bathrooms</p>
              </div>
            </div>
            <Link to={"/tilalAlGhaf#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 2 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/zorah#top"}>
              <img className="w-full h-64 object-cover" src={Prop2} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/zorah#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Al Zorah
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom villa in Alzorah
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Ajman
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">5800 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">4 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">6 Bathrooms</p>
              </div>
            </div>
            <Link to={"/zorah#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 3 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/DownTown#top"}>
              <img className="w-full h-64 object-cover" src={Prop3} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/DownTown#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Burj Lake Hotel
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              2 Bedroom Apartment in The Address Downtown 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">120/1,292 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">2 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">1 Bathroom</p>
              </div>
            </div>
            <Link to={"/DownTown#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-11/12 mx-auto flex flex-col md:flex-row  p-6 gap-12">
        {/* Property Card 1 */}
        
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/Boulevard#top"}>
              <img className="w-full h-64 object-cover" src={Prop4} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/Boulevard#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
             Address Boulevard Residences
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              3 Bedroom Apartment in Downtown Dubai 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">3,458 sqft</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">3 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">3 Bathrooms</p>
              </div>
            </div>
            <Link to={"/Boulevard#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/CBO#top"}>
              <img className="w-full h-64 object-cover" src={Prop5} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/CBO#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Creek Beach Orchid
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              2 Bedroom Apartment in Dubai Creek Harbour 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900"> 1,000 sqft</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">2 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">2 Bathrooms</p>
              </div>
            </div>
            <Link to={"/CBO#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/Maison#top"}>
              <img className="w-full h-64 object-cover" src={Prop6} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/Maison#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              DAMAC Maison Majestine
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
            2 Bedroom Apartment in Majestine in Business Bay 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900"> 1,352 sqft</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">2 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">2 Bathrooms</p>
              </div>
            </div>
            <Link to={"/Maison#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 2 */}
      
      </div>
      <div className="w-11/12 mx-auto flex flex-col md:flex-row  p-6 gap-12">
        {/* Property Card 1 */}
        
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/Opera#top"}>
              <img className="w-full h-64 object-cover" src={Prop7} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/Opera#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
             Opera Grand, Downtown Dubai, Dubai
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
            One bedroom with a modern, open-plan layout 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">741 sqft</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">1 Bedroom</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">1 Bathroom</p>
              </div>
            </div>
            <Link to={"/Opera#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
       

        {/* Property Card 2 */}
      
      </div>
      <Footer/>
    </div>
  );
};

export default OffPlanProperties;
