import React, { useEffect } from "react";
import Service1 from "../Images/Services1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Service2 from "../Images/Services2.png";
import Service3 from "../Images/Services3.png";
import Service4 from "../Images/Services4.png";
import useScrollToTop from "../Scroll";
import { useLocation } from "react-router-dom";

const ServicesSection = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to top if no hash
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div className="w-11/12 mx-auto">
      <div
        id="Effortless"
        data-aos="fade-right"
        data-aos-duration="800"
        className="bg-[#fffefe] flex flex-col md:flex-row p-4 border-2 rounded-xl items-center w-full my-8 mx-auto border-[#E3E3E3]"
      >
        <img
          src={Service1}
          alt="Effortless Property Management"
          className="w-full md:w-1/3 rounded-lg"
        />
        <div className="md:ml-8 mt-4 md:mt-0 flex-1">
          <h1 className="font-semibold text-xl md:text-2xl my-2">
            Effortless Property Management
          </h1>
          <p className="text-sm md:text-base">
          Experience peace of mind with our comprehensive property management solutions, designed to cater to your needs. We'll efficiently handle rent collection, maintenance, tenant screening, and lease agreements, regardless of the number of properties you own. Also, by taking a proactive approach, we guarantee that possible problems are found early on and resolved quickly, reducing the likelihood of interruptions to your rental income flow. Beyond simple property management, our staff provides individualized services catered to your unique needs and preferences. Our goal is to maximize the return on your investment portfolio, whether that means raising tenant satisfaction, implementing cost-effective maintenance plans, or optimizing rental rates. With our expert management, you can relax and enjoy passive income while your investment properties grow.

          </p>
        </div>
      </div>

      <div
        id="SearchSelection"
        data-aos="fade-left"
        data-aos-duration="800"
        className="bg-[#fffefe] flex flex-col md:flex-row p-4 border-2 rounded-xl items-center w-full my-8 mx-auto border-[#E3E3E3]"
      >
        <img
          src={Service2}
          alt="Property Search and Selection"
          className="w-full md:w-1/3 rounded-lg"
        />
        <div className="md:ml-8 mt-4 md:mt-0 flex-1">
          <h1 className="font-semibold text-xl md:text-2xl my-2">
            Property Search and Selection
          </h1>
          <p className="text-sm md:text-base">
            As our staff searches out and chooses the ideal property for you, you can start your property journey with confidence. Whether your dream home is an elegant urban apartment, a peaceful residential haven, or a busy business center, we're committed to finding options that meet your lifestyle and financial goals. With our wide market experience and access to a variety of properties, we'll sift through the listings in search of undiscovered treasures and excellent opportunities. We'll be by your side every step of the way, offering professional advice and tailored recommendations, from the first search to the ultimate decision. Allow us to streamline your property search and provide you with the knowledge you need to make wise choices that will lead to your future real estate success You can rely on us as your trusted partner as you navigate the complexities of the real estate market to ensure a smooth journey towards finding your ideal home and protecting your financial future.

          </p>
        </div>
      </div>

      <div
        id="Investment"
        data-aos="fade-right"
        data-aos-duration="800"
        className="bg-[#fffefe] flex flex-col md:flex-row p-4 border-2 rounded-xl items-center w-full my-8 mx-auto border-[#E3E3E3]"
      >
        <img
          src={Service3}
          alt="Investment Consultancy"
          className="w-full md:w-1/3 rounded-lg"
        />
        <div className="md:ml-8 mt-4 md:mt-0 flex-1">
          <h1 className="font-semibold text-xl md:text-2xl my-2">
            Investment Consultancy
          </h1>
          <p className="text-sm md:text-base">
          Use our extensive investment advisory services to maximize your profits. Our committed staff carefully investigates the market to find profitable prospects that fit your particular financial preferences and aspirations. You can rely on us to be your trustworthy partner, assisting you in selecting the best investment opportunities and offering insightful advice on possible capital gains and rental yields. Furthermore, you will have access to thorough and lucid information thanks to our transparent approach, which gives you the confidence to make wise investment decisions. Our advisory services are tailored to meet your needs and surpass your expectations, whether your goals are to diversify your investments, increase the size of your portfolio, or make long-term plans. Let us assist you in realizing the full return on your investments and setting out on a path to long-term financial success.

          </p>
        </div>
      </div>

      <div
        id="HomeInspection"
        data-aos="fade-left"
        data-aos-duration="800"
        className="bg-[#fffefe] flex flex-col md:flex-row p-4 border-2 rounded-xl items-center w-full my-8 mx-auto border-[#E3E3E3]"
      >
        <img
          src={Service4}
          alt="Home Inspection/Snagging"
          className="w-full md:w-1/3 rounded-lg"
        />
        <div className="md:ml-8 mt-4 md:mt-0 flex-1">
          <h1 className="font-semibold text-xl md:text-2xl my-2">
            Home Inspection/Snagging
          </h1>
          <p className="text-sm md:text-base">
            Ensure the soundness of your real estate investment with our comprehensive house inspection and snagging services. We guarantee a smooth transition into your new home by having our committed staff carefully inspect every detail, including structural integrity and last minute details, to give you peace of mind. Allow us to be your dependable partner in protecting your financial investment and making sure your future house is a true haven. Discover the distinction of collaborating with a group dedicated to your contentment and the enduring integrity of your investment. Our exhaustive examinations cover every detail, giving you a comprehensive evaluation of the state of your property and any possible problems. You can confidently manage the intricacies of buying a house with our assistance, knowing that your investment is in competent hands. Come along with us to ensure a seamless and stress-free move into your new house, where every last detail has been meticulously examined and confirmed for your peace of mind.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
