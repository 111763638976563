import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card1 from "./1.png";
import Card2 from "./2.png";
import Card3 from "./3.png";
import Card4 from "./4.png";
import Card5 from "./5.png";
import Card6 from "./6.png";
import Card7 from "./7.png";
import Card8 from "./8.png";
import Card9 from "./9.png";
import Card10 from "../Images/partner7.jpg";
import Card11 from "./11.png";
import Card12 from "./12.png";
import Card13 from "./13.png";
import Card14 from "./14.png";
import Card15 from "./15.png";
import Card16 from "./16.png";
import Card17 from "./17.png";
import Card18 from "./18.png";
import Card19 from "./19.png";
import Card20 from "./20.png";
import Card21 from "./21.png";
import Card22 from "./22.png";
import Card23 from "./23.png";
import Card24 from "../Images/Arada.png";
import Card25 from "../Images/Bnw.png";
import Card26 from "../Images/Luxe.png";

const DevCards = ({ searchValue }) => {
  // Array of card objects with internal labels and links for filtering
  const cards = [
    { src: Card1, label: "Samana", link: "/Samana#top" },
    { src: Card2, label: "Danube", link: "/Danube#top" },
    { src: Card3, label: "Nakheel", link: "/Nakheel#top" },
    { src: Card4, label: "Damac", link: "/Damac#top" },
    { src: Card5, label: "Azizi", link: "/Azizi#top" },
    // { src: Card6, label: "Sobha", link: "/projects#top" },
    { src: Card7, label: "Aldar", link: "/Aldar#top" },
    { src: Card8, label: "Meraas", link: "/Meraas#top" },
    { src: Card9, label: "Omniyat", link: "/Omniyat#top" },
    { src: Card10, label: "Emaar", link: "/Emaar#top" },
    { src: Card11, label: "Ellington", link: "/Ellington#top" },
    { src: Card13, label: "Mag", link: "/Mags#top" },
    { src: Card14, label: "Select Group", link: "/Select#top" },
    { src: Card15, label: "Binghatti", link: "/Binghatti#top" },
    { src: Card16, label: "Wasi properties", link: "/Wasl#top" },
    { src: Card17, label: "Tilal Al Ghaf", link: "/Tilal#top" },
    { src: Card18, label: "Union Properties", link: "/Union#top" },
    { src: Card19, label: "Tiger Group", link: "/Tiger#top" },
    { src: Card20, label: "Expo City Dubai", link: "/Expo#top" },
    { src: Card21, label: "Al Hatoom", link: "/Alhabtoor#top" },
    { src: Card22, label: "Seven Tides", link: "/Seven#top" },
    { src: Card23, label: "Al Barari", link: "/Albrari#top" },
    { src: Card24, label: "Arada", link: "/Arada#top" },
    { src: Card25, label: "BNW", link: "/BNW#top" },
    { src: Card26, label: "LUXE", link: "/Luxe#top" },
  ];

  // Filter cards based on the search input
  const filteredCards = cards.filter((card) =>
    card.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="px-7 py-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {filteredCards.map((card, index) => (
        <div
          key={index}
          className=" h-56 rounded-lg flex justify-center items-center shadow-md text-center animation-fadeInUp transform transition-transform duration-300 hover:scale-105"
        >
          <Link to={card.link}>
            <img
              src={card.src}
              alt={`Card ${index + 1}`}
              className="rounded-md w-10/12 mb-4 mx-auto"
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default DevCards;
