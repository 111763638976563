import React, { useEffect } from "react";
import About1 from "../Images/About1.png";
import About2 from "../Images/About2.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:w-11/12 md:mx-auto items-start mt-20 p-8 md:p-6 gap-6">
  {/* Left Section - Images */}
  <div className="flex-1 ">
    <img src={About1} alt="" className="rounded-lg  w-full  mb-4" />
   
  </div>

  {/* Right Section - Text Content */}
  <div className="flex-1 flex flex-col space-y-4 md:pl-8">
    <p className="text-[#C69148] font-bold text-sm md:text-base uppercase">Welcome to</p>
    <h2 className="text-3xl mt-3 md:text-3xl font-bold text-[#000]">POINTX REALTY</h2>
    <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Helping You Find Your Place in Dubai</h3>
    <p className="text-sm md:text-base ">
    With extensive experience in the USA and UK, we’re thrilled to bring our real estate expertise to Dubai. Specializing in investments and renovations, we uncover hidden gems in both commercial and residential sectors. Our innovative approach connects you with unique properties that maximize value and potential. Join us in exploring a collaborative world of opportunity in real estate excellence. Together, let’s reshape the future of property investment and achieve your vision.

    </p>

    <div className="space-y-4">
      <div className="flex items-start">
        {/* <img src={Icon1} alt="Expert Care Icon" className="h-6 w-6 mr-3" /> */}
        <p className="text-sm md:text-base">
          <strong>Expert Knowledge and Personalized Service:
          </strong><br /> <br/>
          Our experienced team has an in-depth understanding of Dubai’s dynamic market, providing tailored advice to meet each client’s needs. From analyzing market trends to identifying prime locations, our insights simplify your real estate journey. We prioritize client satisfaction, guiding you with transparency and professionalism. With PointX, you gain a trusted partner who ensures a smooth experience from start to finish
        </p>
      </div>
      <div className="flex items-start">
        {/* <img src={Icon2} alt="Affordable Pricing Icon" className="h-6 w-6 mr-3" /> */}
        <p className="text-sm md:text-base">
          <strong>Diverse Portfolio Across Prime Locations:
          </strong><br /><br />
          Our portfolio includes a diverse selection of high-quality residential and commercial properties in Dubai’s iconic neighborhoods. We offer options suited to various tastes, lifestyles, and budgets, ensuring you find a property that aligns with your goals. Each listing is carefully curated for value, quality, and growth potential. With PointX, your investment becomes a meaningful step toward your aspirations in Dubai.
        </p>
      </div>
    </div>

    {/* Contact Button */}
    <div className="pt-6">
      <Link
        to="/about#top"
        className="px-6 py-2 bg-[#D8B56D] mt-10 text-white text-lg font-semibold rounded-full hover:bg-[#c18e32]"
      >
LEARN MORE
      </Link>
    </div>
  </div>
</div>

  );
};

export default AboutUs;
