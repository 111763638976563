import React, { useState } from "react";
import Partner1 from "../Images/10.png";
import Partner2 from "../Images/3.png";
import Partner3 from "../Images/8.png";
import Partner4 from "../Images/Arada.png";
import Partner5 from "../Images/5.png";
import Partner6 from "../Images/4.png";
import Partner7 from "../Images/2.png";
import Partner8 from "../Images/1.png";
import Partner9 from "../Images/15.png";
import Partner10 from "../Images/partner10.png";
import { Link } from "react-router-dom";

const partnerImages = [
  { src: Partner1, label: "EMAAR", link: "/Emaar#top" },
  { src: Partner2, label: "NAKHEEL", link: "/Nakheel#top" },
  { src: Partner3, label: "MERAAS", link: "/Meraas#top" },
  { src: Partner4, label: "Arada",link:"/Arada#top" },
  { src: Partner5, label: "AZIZI", link: "/Azizis#top" },
  { src: Partner6, label: "DAMAC", link: "/DAMAC#top" },
  { src: Partner7, label: "DANUBE", link: "/Danube#top" },
  { src: Partner8, label: "SAMANA", link: "/Samana#top" },
  { src: Partner9, label: "BINGHATTI", link: "/Binghatti#top" },
];

const Partners = () => {
  const [filter, setFilter] = useState("All");

  const filteredImages =
    filter === "All"
      ? partnerImages
      : partnerImages.filter((image) => image.label === filter);

  const filters = [
    "All",
    "Developer A",
    "Developer B",
    "Developer C",
    "Developer D",
  ];

  return (
    <section className="p-6 border-2 mb-10">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold">OUR PARTNERS</h2>
        <p className="text-[#4B5162] py-6">
          We've teamed up with the top real estate developers.
        </p>
      </div>

      {/* Image Gallery */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 w-3/4 mx-auto">
        {filteredImages.map((image, index) => (
          <Link
            key={index}
            to={image.link} // Linking each image to its respective page
            className="group overflow-hidden rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
          >
            <div className="flex justify-center items-center h-40 p-3">
              {/* Adjust height as needed */}
              <img
                src={image.src}
                alt={`Partner ${index + 1}`}
                className="object-contain h-full w-40"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex justify-center items-center">
                <p className="text-white font-semibold text-lg">
                  {image.label}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="flex justify-center py-10">
        <Link
          to={"/developers#top"}
          type="button"
          className="text-white bg-[#D8B56D] w-[20%] font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Explore all
        </Link>
      </div>
    </section>
  );
};

export default Partners;
