import React, { useState } from "react";
import HeroServices from "../ServicesComponents/HeroServices";
import ServicesSection from "../ServicesComponents/ServicesSection";
import ServicesCard from "../ServicesComponents/ServicesCard";
import Footer from "../WhyDubaiComps/Footer";
import ContactForm from "../AboutComponents/ContactForm";

const Services = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <HeroServices />
      <div className="my-20 text-center text-xl px-4 ">
        Elevate your real estate experience with our comprehensive suite of
        services designed to simplify property management <br className="hidden sm:flex" /> and investment
        consultancy in Dubai.
      </div>
      <ServicesSection />
      <ServicesCard />
      <ContactForm />

      <Footer />
    </div>
  );
};

export default Services;
