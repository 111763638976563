import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail3.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page3 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav><img className="w-full" src={Detail1} alt="" />
      <h1 className="my-6 text-center text-4xl">Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values</h1>
        <div className=" mx-auto flex items-center flex-col">
        <div className="w-10/12">
          <p className="mt-5 ">
            In January 2024, there was an increase in the price of apartments in
            Dubai, which showed the highest growth in the last ten years,
            according to ValuStrat Price Index (VPI).
          </p>

          <p className="my-10 ">
            Dubai has recorded the highest rise in the annual percentage change
            in price of apartments in the past ten years in January 2024, as
            revealed by the ValuStrat Price Index (VPI). The overall VPI index,
            indicating fluctuations in capital and rental values of residential
            and commercial buildings, had a clear upward trend, especially in
            the apartment market.
          </p>

          <p className="my-10 ">
            The surge in property valuations persisted throughout January, with
            the VPI recording a 21.5% year-on-year increase, reaching 160.6
            points. This consistent growth trajectory, averaging 2% monthly,
            propelled apartment values to 134 points and villas to 201.4 points.
            This rise is significantly higher than the index’s initial score of
            100 points set back in January 2021 or even the previous record high
            of 172.
          </p>

          <p className="my-10 ">
            Analyzing monthly variations, it was revealed that apartments’
            capital gain rate went up by 1.8% with an annual growth of 17%;
            however, key locations like Discovery Gardens, Palm Jumeirah, and
            the Greens have registered a gross percentage growth. Similarly,
            villa values surged by 2.3% monthly, with an impressive annual
            increase of 26.4%, particularly in sought-after locations like the
            Jumeirah Islands and Palm Jumeirah.
          </p>

          <p className="my-10 ">
            Market dynamics also indicated a shift in transaction patterns, with
            a rise in off-plan property registrations and a slight decline in
            ready-home sales, albeit still higher than the previous year.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page3;
